
/*============================================================================
  TABLES
==============================================================================*/

table {
  width: 100%; padding: 0; border-collapse: separate; border-spacing: 0;
  empty-cells: show; margin: 0 0 1.5em;

  tr {
    &:nth-child(odd) {
      background-color: $grayLight;
    }
  }

  th, td {
    padding: 12px 15px; text-align: left; vertical-align: middle; line-height: 1.5em;
  }

  th {
    font-weight: bold;
  }
}

.doc-table {
  margin-bottom: $gutter;
  tr {
    td {
      &:first-child {
        font-weight: 700;
        color: $black;
        .icon {
          height: 15px;
          width: 15px;
          margin-right: 5px;
          position: relative;
          top: -1px;
        }
        @media #{$maxMedium} {
          display: block;
          text-align: center;
        }
      }
      &:last-child {
        text-align: right;
        @media #{$maxMedium} {
          display: block;
          text-align: center;
          padding-top: 0;
        }
      }
    }
  }
  @media #{$maxMedium} {
    font-size: 14px;
  }
}
