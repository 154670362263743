.animato {

}

.animato--fade-up-visual {
  opacity: 0;
  transition: 1.2s;
  width: 100%;
  transform: translateY(15px);
  .visual-item__image-wrap, .visual-wide, .card--visual .card__content {
    &:after {
      transform: translateY(15px);
      transition: 1.2s;
      transition-delay: 0.6s;
    }
  }
  &.revealed {
    opacity: 1;
    transform: translateY(0px);
    .visual-item__image-wrap, .visual-wide, .card--visual .card__content {
      &:after {
        transform: translateY(0px);
      }
    }
  }
}

.animato--fade-up {
  opacity: 0;
  transform: translateY(15px);
  transition: 1.5s;
  width: 100%;
  &.revealed {
    opacity: 1;
    transform: translateY(0px);
  }
}

// modernizr

.no-js {
  // .animato--fade-down {
  //   opacity: 1;
  //   transform: translateY(0px);
  // }

  // .animato--fade-from-left {
  //   opacity: 1;
  //   transform: translateX(0px);
  // }
}

