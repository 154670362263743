/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */

/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */

/* =============================================================================
   IMPORTS
   ========================================================================== */

// CSS Reset
@import "inc/reset";

// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";

// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";

// Plugins
@import "plugins/validation-engine";
@import "plugins/modaal";
@import "plugins/animato";
//@import "plugins/fancybox";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;
}

body {
  @include default-font;
  color: $bodyText;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  @include optimize-font;
  overflow: hidden;
}

.page-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;

  .container {
    padding: 0;
  }

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--tiny {
  max-width: 760px;
}
.container--small {
  max-width: 960px;
}
.container--large {
  max-width: 1480px;
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}

/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  fill: $black;
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 3px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before,
  &:after {
    content: "";
    margin-left: 0;
  }
  &:before {
    margin-top: 3px;
  }
  &:after {
    margin-top: 9px;
  }
}

.a-c {
  text-align: center;
}
.medium--center {
  @media #{$medium} {
    text-align: center;
  }
}

.pos-rel {
  position: relative;
}

// Exceptions: only add what you need!
// .mb0.mb0 { margin-bottom: 0; }
// .mb20.mb20 { margin-bottom: 20px; }
// .mb40.mb40 { margin-bottom: 40px; }

.mb1.mb1 {
  margin-bottom: $gutter;
}
.mb2.mb2 {
  margin-bottom: $gutter * 2;
}

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  flex-wrap: wrap;

  > * {
    display: flex;
    align-items: flex-start;
  }

  &:before,
  &:after {
    display: none;
  }
}

.equal-h--align {
  > * {
    display: flex;
    align-items: initial;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div {
      float: left;
    }
  }
}

// Vertical align (not recommended)
.va-wrap,
.va-m,
.va-b {
  display: table;
  width: 100%;
  height: 100%;
}
.va-wrap {
  table-layout: fixed;
}
.va-m,
.va-b {
  display: table-cell;
}
.va-m {
  vertical-align: middle;
}
.va-b {
  vertical-align: bottom;
}

// Block link
.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.page-header__inner {
  @include clearfix;
  position: relative;
  padding: $gutter * (1.5) 0 $gutter;
  @media #{$large} {
    padding: $gutter * (1.5) 0;
  }
}

.page-header__logo {
  text-decoration: none;
  //@include opacity(100);
  float: left;
  transform: scale(1);

  img {
    display: block;
    width: 90px;
    height: 55px;
  }

  &:hover,
  &:focus {
    //@include opacity(80);
    transform: scale(1.02);
  }

  @media #{$medium} {
    img {
      width: 120px;
      height: 73px;
    }
  }
  @media #{$large} {
    img {
      width: 140px;
      height: 85px;
    }
  }
}

.page-header__hours {
  position: absolute;
  top: 0;
  right: 0;

  display: inline-block;
  transition: $transitionDefault;
  outline: 0;
  line-height: 1.2em;
  padding: 14px 22px;
  margin: 0;
  text-decoration: none;
  font-weight: 700;
  background-color: $black;
  color: $white;
  border-radius: $radius;
  min-width: 175px;
  text-align: center;
  font-size: 13px;
  .icon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    margin-top: -2px;
    position: relative;
    fill: $white;
  }
  @media #{$maxMedium} {
    width: 100%;
    font-size: 12px;
    padding: 8px 22px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    .icon {
      width: 10px;
      height: 10px;
    }
  }
}
.page-header__hours--open {
  color: $success;
  .icon {
    fill: $success;
  }
}
.page-header__hours--closed {
  color: $error;
  .icon {
    fill: $error;
  }
}

.main-nav {
  @include optimize-font;
  color: $white;
  font-weight: 700;

  .main-nav__link {
    text-decoration: none;
    color: $white;
    padding: 12px 15px;
    display: block;
    border-top: 1px solid $grayLight;
    font-size: 14px;
  }

  .main-nav__link:hover,
  .main-nav__link:focus,
  li.active .main-nav__link {
    color: $accent;
  }

  @media #{$medium} {
    margin: 0;
    margin-top: 20px;
    float: right;

    li {
      float: left;
      margin-left: $gutter * 1.5;
    }

    .main-nav__link {
      border-top: 0;
      padding: 0;
    }
    .button {
      margin-left: $gutter;
    }
  }

  @media #{$large} {
    li {
    }
  }
}

.main-nav__trigger {
  display: block;
  position: relative;
  padding: 12px 0px 12px 35px;
  background-color: transparent;
  color: $black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  right: 0px;
  min-width: 0px;
  color: $white;
  font-weight: 700;
  width: auto;
  top: 50px;

  .icon-hamburger {
    position: absolute;
    left: 0px;
    top: 14px;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
    color: $white;
  }

  &:active {
    transform: none;
  }

  @media #{$medium} {
    top: 60px;
  }

  @media #{$large} {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0 0 15px;

  @media #{$large} {
    margin: 0 0 0 0;
    display: flex;
    align-items: center;
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}

/* Lang selector */

.lang-selector {
  position: relative;
  z-index: 5;
  display: inline-block;
  color: $white;
  font-size: 14px;
  .lang-selector__trigger {
    position: relative;
    padding: 0px 18px 0px 0px;
    text-align: center;
    cursor: pointer;
    transition: $transitionDefault;
    &:after {
      content: "";
      display: inline-block;
      background-image: url("../images/svg/chevron-down-white.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 8px;
      height: 8px;
      position: absolute;
      right: 3px;
      top: 10px;
    }
    &:hover,
    &:focus {
      color: $accent;
    }
  }
  .lang-selector__list {
    position: absolute;
    display: none;
    left: 0;
    top: 100%;
    width: 100%;
    transition: $transitionDefault;
    margin-bottom: 0;
    list-style: none;
    margin: 0;
    text-align: left;
    li {
      margin: 0;
      float: none;
      &:after {
        display: none;
      }
    }
    a {
      text-align: left;
      display: block;
      width: 100%;
      color: $white;
      padding: 2px 0;
      &:hover,
      &:focus {
        color: $accent;
        text-decoration: none;
      }
    }
  }
  &.open {
    .lang-selector__trigger {
      &:after {
        transform: scaleY(-1) translateY(2px);
      }
    }
  }
}

/* Navigation popup */

.navigation-popup {
  .modaal-content {
    height: 100%;
  }
  .modaal-container {
    background-color: $black;
  }
  .modaal-content-container {
    height: 100%;
  }
}

.main-nav-mobile {
  list-style: none;
  margin: 0;
  text-align: center;
  li {
    margin-bottom: $gutter/2;
    &.active {
      color: $accent;
    }
  }
  .main-nav__link {
    font-size: 22px;
    color: $white;
    &:hover,
    &:focus {
      color: $accent;
    }
  }
}

.lang-inline-list {
  list-style: none;
  margin: 0;
  font-size: 16px;
  margin-bottom: $gutter;
  li {
    display: inline-block;
    margin: 0px 5px;
    a {
      color: $white;
    }
    &.active {
      a {
        color: $accent;
      }
    }
  }
}

/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  padding: 0px 0 $gutter * 2;
  flex-grow: 1;

  @media #{$medium} {
    padding: 0px 0 $gutter * 4;
  }
}

/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  background-color: $black;
  color: $white;
  text-align: center;

  .contact-list {
    a {
      color: $white;
    }
    .icon {
      fill: $white;
    }
  }

  a {
    color: $white;
  }

  @media #{$small} {
    text-align: left;
  }

  @media #{$medium} {
    z-index: 5;
  }
}
.page-footer__content {
  padding: $gutter * 2 0;
  .grid {
    .grid__item {
      margin-bottom: $gutter;
      &:last-child {
        margin-bottom: 0;
      }
      @media #{$medium} {
        margin-bottom: 0;
      }

      > .grid {
        > .grid__item {
          @media #{$small} {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.page-footer__bottom {
  position: relative;
  border-top: 2px solid $accent;
  padding: $gutter/2 0;
  @media #{$medium} {
    padding-right: 100px;
  }
}
.page-footer__title {
  @extend .h4;
  color: $white;
  margin-bottom: $gutter/2;
  @media #{$medium} {
    margin-bottom: 40px;
  }
}
.page-footer__list {
  list-style: none;
  margin: 0;

  a {
    &:hover,
    &:focus {
      color: $accent;
    }
  }

  li {
    display: flex;

    + li {
      margin-top: 0.5em;
    }
  }
}
.page-footer__list--social {
  .icon {
    width: 15px;
    height: 15px;
    fill: $white;
    margin-right: 10px;
    position: relative;
    top: -1px;
  }
}
.page-footer__inline-list {
  list-style: none;
  margin: 0;
  li {
    color: $accent;
    display: inline-block;
    &:after {
      content: "-";
      display: inline-block;
      padding: 0px 3px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  a {
    color: $accent;
    text-decoration: underline;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.page-footer__logos {
  margin-top: $gutter;
}

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign-white.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  margin: $gutter/2 auto;
  &:hover,
  &:focus {
    opacity: 1;
  }
  @media #{$medium} {
    margin: 0;
    width: 16px;
    position: absolute;
    right: 0;
    top: 18px;
    &:hover,
    &:focus {
      opacity: 1;
      width: 80px;
    }
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover,
    &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article-detail {
}

.article-detail__image {
  margin-bottom: 1.5em;
}

// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;

  ul,
  p {
    margin-bottom: 1em;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

// Slideshow
.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: $transitionDefault;

    &:hover,
    &:focus {
      transition: none;
    }
  }

  span:hover,
  span:focus,
  .cycle-pager-active {
    text-indent: 0;
  }
}

// Map
.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;

  @media #{$medium} {
    height: 550px;
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: $accent;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3c5b9b;
  &:hover,
  &:focus {
    background-color: darken(#3c5b9b, 8%);
  }
}

.social__link--twitter {
  background-color: #2daae1;
  &:hover,
  &:focus {
    background-color: darken(#2daae1, 8%);
  }
}

.social__link--pinterest {
  background-color: #cb2027;
  &:hover,
  &:focus {
    background-color: darken(#cb2027, 8%);
  }
}

.social__link--linkedin {
  background-color: #0173b2;
  &:hover,
  &:focus {
    background-color: darken(#0173b2, 8%);
  }
}

/* Contact list */

.contact-list {
  margin: 0;
  list-style: none;
  li {
    @extend .h3;
    margin-bottom: $gutter/3;
    font-weight: 400;
  }
  .icon {
    margin-right: $gutter/2;
    position: relative;
    top: -2px;
  }
}

/* Hero */

.hero {
  position: relative;
  padding-top: 115px;
  h1,
  p {
    color: $white;
  }
  .rte {
    a {
      color: $white;
    }
  }
  @media #{$medium} {
    padding-top: 133px;
  }
  @media #{$large} {
    padding-top: 175px;
    min-height: 450px;
  }
}
.hero__content {
  width: 100%;
  padding: $gutter 0 $gutter * 2;
  .container {
    width: 100%;
  }
}
.hero__image {
  background-color: $black;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  right: 0px;
  z-index: -1;
}
.hero__title {
  position: relative;
  margin-bottom: 0;
  color: $white;
  z-index: 5;
  text-shadow: 1px 1px 2px #00303c;
  span {
    display: inline-block;
  }
}
.hero-page {
  margin-top: -$gutter * 4;
  @media #{$large} {
    margin-top: -$gutter * 5;
  }
}
.hero-intro__content {
  padding: $gutter * 2 0 $gutter;
  @media #{$medium} {
    padding: $gutter * 2 $gutter 0 0;
  }
}
.hero-intro__image {
  display: block;
  position: relative;
  max-width: none;
  background-size: cover;
  background-position: center center;
  padding-bottom: 56.25%;
  border-radius: $radius;
  @media #{$medium} {
    padding-bottom: 0;
    height: 350px;
    width: calc(50vw);
    margin-top: -175px;
  }
  @media #{$large} {
    height: 550px;
    margin-top: -275px;
  }
}

.hero-intro__card.hero-intro__card {
  @extend .card;
  display: block;
  position: relative;
  max-width: none;
  background-size: cover;
  background-position: center center;
  border-radius: $radius;
  .card__content {
    max-width: 765px;
    @media #{$medium} {
      padding: 60px 80px 60px 60px;
    }
  }
  @media #{$medium} {
    padding-bottom: 0;
    width: calc(50vw);
    margin-top: -175px;
  }
  @media #{$large} {
    margin-top: -275px;
  }
}

/* Hero home */

.hero--home {
  .hero__content {
    padding: $gutter 0 $gutter * 2;
    @media #{$medium} {
      padding: 0 0 $gutter * 4 0;
    }
  }
  .hero__banner {
    @media #{$medium} {
      min-height: 500px;
    }
  }
  .hero__title {
    @media #{$medium} {
      padding-top: $gutter;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 3px;
    height: 50px;
    background-color: $accent;
    left: 50%;
    bottom: 0;
    transform: translateY(50%) translateX(-50%);
  }
}

/* Hero up */

.hero--up {
  .hero__content {
    padding: $gutter 0 $gutter * 5;
  }
}

.hero__grid {
  @media #{$medium} {
    display: flex;
    flex-wrap: nowrap;
  }
}
.hero__description {
  margin-bottom: 0;
  margin-top: $gutter;
  @media #{$medium} {
    padding-right: $gutter * 2;
  }
}
.hero__buttons.hero__buttons {
  margin-top: $gutter;
}
.hero__banner {
  position: relative;
  height: 100%;
  border-radius: $radius;
  margin-top: $gutter;
  min-height: 250px;
  @media #{$medium} {
    padding-bottom: 0;
    margin: 0;
    width: calc(50vw);
  }
}
.hero__banner-visual {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: $radius;
  @media #{$medium} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.hero__banner-stroke {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: $radius;
  @media #{$medium} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.hero__banner-stroke-item {
  list-style: none;
  margin: 0;
  li {
    display: inline-block;
    width: 100%;
    > * {
      width: 100%;
    }
    @media #{$large} {
      width: auto;
    }
  }
}
.hero__banner-label {
  @extend .button;
  background-color: $accentSecondary;
  color: $white;
  cursor: default;
  border-radius: 0;
  text-transform: lowercase;
  &:hover,
  &:focus {
    background-color: $accentSecondary;
    color: $white;
  }
  @media #{$large} {
    border-bottom-left-radius: $radius;
  }
}
.hero__banner-cta {
  @extend .button;
  @extend .button--underline;
  font-size: 12px;
  padding-left: 20px;
  .icon {
    width: 10px;
    height: 10px;
    margin-left: 8px;
    transition: $transitionDefault;
    transform: translateX(0px);
  }
  text-align: center;
  @media #{$large} {
    text-align: left;
  }
  &:hover,
  &:focus {
    .icon {
      transform: translateX(3px);
      fill: $linkColorHover;
    }
  }
}

.hero__banner-video {
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  video {
    position: absolute;
    left: 50.1%;
    top: 50%;
    width: 100%;
    height: auto;
    min-height: 100%;
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -o-object-fit: cover;
    object-fit: cover;
  }

  @media #{$large} {
    height: 99%;
    width: 99%;

    .video {
      left: 50%;
    }
  }
}

/* Sections */

.section {
  padding: $gutter * 2 0;
  + .section {
    padding-top: 0;
  }
  @media #{$medium} {
    padding: $gutter * 4 0;
  }
}

.pb0 {
  padding-bottom: 0;
}

.section__hero {
  display: block;
  height: $gutter * 4;
  background-size: cover;
  background-position: center center;
  @media #{$medium} {
    height: 300px;
    display: block;
  }
}
.section__hero-pull {
  margin-top: -$gutter * 2;
  @media #{$medium} {
    margin-top: -150px;
  }
}

/* Visual item */

.visual-item {
}
.visual-item--rev {
  > .grid {
    @extend .grid--rev;
  }
  > .equal-h {
    @media #{$medium} {
      flex-wrap: revert;
    }
  }
  @media #{$medium} {
    .visual-item__content {
      align-items: flex-end;
      text-align: right;
      //padding-left: 0;
      padding-right: $gutter;
      @media #{$large} {
        padding-right: $gutter * 2;
      }
    }
    .visual-item__image-wrap {
      &:after {
        right: auto;
        left: -$gutter/2;
        bottom: -$gutter/2;
      }
    }
  }
}
.visual-item__content {
  padding: 0;
  max-width: 100%;
  width: 100%;
  @media #{$medium} {
    padding: $gutter;
    padding-right: 0;
  }
  @media #{$large} {
    padding: $gutter * 2;
  }
}
.visual-item__image-wrap {
  position: relative;
  width: 100%;
  margin-bottom: $gutter;
  border-radius: $radius;
  background-size: cover;
  background-position: center center;
  padding-bottom: 80%;
  &:after {
    content: "";
    position: absolute;
    display: block;
    right: -$gutter/2;
    bottom: -$gutter/2;
    background-color: $accent;
    border-radius: $radius;
    z-index: -1;
    width: 33%;
    height: calc(100% - 30px);
  }
  @media #{$medium} {
    margin-bottom: 0;
  }
}
.visual-item__image {
  display: none;
  border-radius: $radius;
  width: 100%;
}

/* Visual wide */

.visual-wide {
  position: relative;
  border-radius: $radius;
  background-size: cover;
  background-position: center center;
  padding-bottom: 56.25%;
  &:after {
    content: "";
    position: absolute;
    display: block;
    left: -$gutter/2;
    right: -$gutter/2;
    bottom: -$gutter/2;
    background-color: $accent;
    border-radius: $radius;
    z-index: -1;
    height: calc(100% - 60px);
  }
}
.visual-wide__image {
  display: none;
}

/* Icon bar */

.icon-bar {
  list-style: none;
  margin: 0 auto;
  max-width: 480px;
  li {
    display: inline-block;
    font-weight: 700;
    color: $black;
    width: 49%;
    font-size: 12px;
    margin-bottom: $gutter/2;
    text-align: center;
    &:after {
      content: "";
      position: relative;
      top: 7px;
      width: 1px;
      height: 25px;
      background-color: $black;
      display: none;
      @media #{$medium} {
        display: inline-block;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    @media #{$small} {
      font-size: 14px;
    }
    @media #{$medium} {
      width: auto;
      margin-bottom: 0;
      text-align: left;
      &:after {
        margin: 0px $gutter/2;
      }
    }
    @media #{$large} {
      font-size: 16px;
      &:after {
        margin: 0px $gutter;
      }
    }
  }
  span {
    display: block;
    @media #{$medium} {
      display: inline-block;
    }
  }
  .icon {
    fill: $accentSecondary;
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
    @media #{$medium} {
      margin-right: $gutter/3;
      margin-bottom: 0;
    }
  }
  @media #{$medium} {
    max-width: 100%;
  }
}

/* Card */

.card {
  box-shadow: 0px 5px 20px 0px rgba(0, 48, 60, 0.1);
  border-radius: $radius;
  background-color: $white;
  position: relative;
  width: 100%;
  transition: $transitionDefault;
  @include clearfix;
}
.card__sidebar {
  padding: $gutter $gutter 0;
  @media #{$medium} {
    float: left;
    padding: $gutter * 2 0 0 $gutter * 2;
    width: 280px;
    + .card__content {
      float: left;
      width: calc(100% - 280px);
    }
  }
}
.card__content {
  padding: $gutter $gutter;
  @media #{$medium} {
    padding: $gutter * 2 $gutter * 2;
  }
}
.card__visual {
  display: block;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 0 0 $radius $radius;
  position: relative;
  z-index: 1;
}
.card__icon {
  position: absolute;
  right: $gutter/2;
  top: $gutter/2;
  width: 25px;
  height: 25px;
  @media #{$medium} {
    width: 35px;
    height: 35px;
    right: 20px;
    top: 20px;
  }
}
.card-button {
  text-align: center;
  margin-top: $gutter/2;
  @media #{$medium} {
    margin-top: $gutter;
  }
}

.card--link {
  transform: translate3d(0, 0, 0);
  .card__link-target {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 100%;
      z-index: 2;
    }
  }
  h2 {
    transition: $transitionDefault;
  }
  .card__link-button {
    position: relative;
    z-index: 3;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    box-shadow: 0px 8px 23px 0px rgba(0, 48, 60, 0.2);
    transform: translate3d(0, -2px, 0);
    h2 {
      color: $linkColorHover;
    }
  }
}

.card--visual {
  .card__content {
    position: relative;
    text-align: center;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: calc(100% - 120px);
      left: 60px;
      bottom: 0;
      height: $gutter/2;
      background-color: $accent;
    }
    @media #{$maxMedium} {
      padding-bottom: $gutter + 15px;
    }
  }
}

/* =============================================================================
   PAGES
   ========================================================================== */

// HOME

/* Title inline */

.title-inline {
  margin-bottom: $gutter;
  h1,
  h2 {
    display: block;
    margin-bottom: 0.8em;
  }
  @media #{$medium} {
    display: flex;
    align-items: center;
    justify-content: center;
    h1,
    h2 {
      display: inline;
      margin-bottom: 0;
    }
    .button {
      margin-left: $gutter;
    }
  }
}

/* Brand list */

.brand-list {
  list-style: none;
  margin: 0;
  text-align: center;
  @include clearfix;
  li {
    float: left;
    width: 50%;
    margin: $gutter/2 0;
    padding: 0 $gutter/2;
  }
  @media #{$small} {
    li {
      width: 25%;
    }
  }
}
.brand-list--centered {
  @media #{$small} {
    li {
      float: none;
      display: inline-block;
    }
  }

  .brand-list__item {
    width: 90%;
  }
}
.brand-list__item {
  display: inline-block;
  width: 100%;
  span {
    display: inline-block;
    height: 50px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  &:hover,
  &:focus {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }
}

/* Card visual */

.card-visual-group {
  .grid__item {
    margin-bottom: $gutter;
    &:last-child {
      margin-bottom: 0;
    }
  }

  & .rte {
    @media #{$medium} {
      min-height: 10.5em;
    }

    @media #{$large} {
      min-height: 7.5em;
    }

    @media #{$larger} {
      min-height: 5.5em;
    }
  }
}

.card--middle {
  background-color: #00303c;

  & a {
    color: white;
  }

  & span {
    color: rgb(204, 204, 204);
  }
}

/* Services */

.card-group {
  .card {
    margin-bottom: $gutter;
  }
}

/* Catalog */

.grid--title {
  margin-bottom: $gutter;
  h1,
  h2,
  h3 {
    margin-bottom: 0;
  }
  > .grid__item {
    &:last-child {
      @media #{$medium} {
        text-align: right;
      }
    }
  }
}

// CONTACT

.dl-contact {
  margin-top: $gutter;
  margin-bottom: $gutter;
  @include clearfix;
  dt {
    display: inline-block;
    width: 80px;
    float: left;
    color: $black;
  }
  dd {
    display: inline-block;
    width: calc(100% - 80px);
    float: left;
    color: $bodyText;
    a {
      color: $bodyText;
      &:hover,
      &:focus {
        color: $black;
      }
    }
  }
  @media #{$medium} {
    margin-top: $gutter * 2;
  }
}

.contact-map {
  display: block;
  margin-top: $gutter * 2;
  border-radius: $radius;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
  @media #{$medium} {
    padding-bottom: 35%;
  }
}

// INLINE EDITING

[contenteditable] {
  border: 1px dashed $accentSecondary;
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }

  .page-footer,
  .main-nav {
    display: none;
  }

  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}
