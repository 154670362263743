
/*============================================================================
  TYPOGRAPHY
==============================================================================*/

// Font Stacks
@mixin default-font($size: 16, $weight: 500, $lineHeight: 1.8em) {
  font-family: 'BwModelicaSS01' ,Helvetica, Arial, Verdana, sans-serif;
  @include fs($size);
  line-height: $lineHeight;
  font-weight: $weight;
}

@mixin display-font() {
  font-family: 'BwModelicaSS01', Helvetica, Arial, Verdana, sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: 'BwModelicaSS01';
  src: url('../fonts/BwModelicaSS01-Medium.woff2') format('woff2'),
  url('../fonts/BwModelicaSS01-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'BwModelicaSS01';
  src: url('../fonts/BwModelicaSS01-Bold.woff2') format('woff2'),
  url('../fonts/BwModelicaSS01-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}


// Body

p {
  line-height: 1.8em;
  margin-bottom: 1.2em;
  &.accent--secondary {
    color: $accentSecondary;
    strong {
      color: $accentSecondary;
    }
  }
  @media #{$medium} {
    margin-bottom: 1.8em;
  }
}

strong {
  font-weight: 600;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit; font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: none;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover, &:focus {
    color: $linkColorHover;
  }

  &:focus, &:active {
    outline: 0;
  }

}

a[href^=tel]{
  text-decoration:none;
  &:hover, &:focus {
    text-decoration: none;
  }
}

a:not([href]) {
  color: $black; text-decoration: none;
  &:hover, &:focus {
  }
}

img {
  max-width: 100%; height: auto; display: inline-block; vertical-align: middle;
}

// Rte

.rte {
  a {
    font-weight: 700;
    text-decoration: underline;
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-bottom: 1.2em;
  }
  .button {
    text-decoration: none;
  }
  .button--underline {
    padding: 0;
    font-weight: 400;
    text-decoration: underline;
  }
  strong {
    color: $black;
  }
  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include display-font;
  line-height: 1.2em;
  margin: 0 0 0.8em;
  color: $black;

  a {
    text-decoration: none;
    outline: 0; font-weight: inherit;

    &:hover, &:focus {
      color: $accentHover;
    }
  }
}

h1, .h1 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 32px, 52px);
}

h2, .h2 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 24px, 40px);
}

h3, .h3 {
  @include fluid-type(font-size, $mediumSize, $largeSize, 18px, 24px);
}

h4, .h4 {
  @include fs(16);
}

h5, .h5 {  }

h6, .h6 {  }


// Lists

ul, ol {
  margin: 0 0 1.5em 16px;

  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;

  ul {
    list-style: circle;
  }

  &.lined {
    @extend .unstyled;
    li {
      border-bottom: 1px solid #E3E3E3; padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0; border-bottom: none;
      }
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled, nav ul {
  margin: 0 0 1.5em; padding: 0; list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none; list-style-image: none;
    padding: 0; background: none;
  }
}

.subnav-list {
  list-style: none;
  counter-reset: counter;
  margin-left: 0;
  margin-bottom: 0;
  font-size: 18px;
  li {
    position: relative;
    counter-increment: counter;
    padding-left: 20px;
    margin-bottom: $gutter/3;
    &:before {
      content: counter(counter)".";
      color: $accent;
      position: absolute;
      left: 0;
    }
    &.active {
      a {
        color: $black;
        text-decoration: underline;
      }
    }
  }
  a {
    color: $bodyText;
    &:hover, &:focus {
      color: $black;
    }
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt, dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}


// Misc

hr {
  display: block; height: 1px;
  border: 0; border-top: 1px solid $gray; margin: 50px 0;
}

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid $gray;
  margin-bottom: 1.5em;
  @include fs(20);
  font-style: italic;

  p {
    margin-bottom: 0.5em;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    padding-left: 40px;
  }
}
