
/*============================================================================
  FORMS
==============================================================================*/

form {
  margin: 0;
}

legend {
  border: 0; padding: 0; white-space: normal; *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0; vertical-align: baseline; *vertical-align: middle;
  transition: $transitionDefault;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

// When having a body font-size < 16px
// Prevent zoom on touch devices in active inputs
// @media #{$maxMedium} {
//   input, textarea {
//     font-size: 16px;
//   }
// }

label, .label, input, select, textarea {
  @include default-font();
  vertical-align: middle; color: $bodyText;
}


label, .label {
  display: block; margin-bottom: 6px;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;  cursor: pointer; *overflow: visible; border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px 10px; display: block;
  height: 47px; line-height: inherit; width: 100%;
  background-color: $grayLight;
  border: 1px solid $grayLight;
  border-radius: $radius;
  &:focus { outline: 0; border-color: $grayLight; }
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto; vertical-align: top; resize: vertical; width: 100%; height: 185px;
  padding: 10px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0; padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; width: auto; height: auto; padding: 0;
  margin: 3px 0; *margin-top: 0; *height: 13px; *width: 13px; line-height: normal; border: none;
}

input[type="file"] {
  background-color: #fff; padding: initial; border: initial; line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

// Buttons
input[type="reset"], input[type="submit"], .button, button {
  display: inline-block;
  transition: $transitionDefault;
  outline: 0; line-height: 1.2em;
  padding: 14px 22px;
  margin: 0;
  text-decoration: none;
  font-weight: 700;
  background-color: $accent;
  border-radius: $radius;
  color: $black;
  min-width: 175px;
  text-align: center;

  .icon {
    width: 16px;
    height: 15px;
    margin-right: 6px;
    margin-top: -2px;
    position: relative;
    transition: $transitionDefault;
  }

  &:hover, &:focus, &:active {
    background-color: $accentHover;
    color: $black;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  @media #{$maxSmall} {
    width: 100%;
  }

}

.button--underline {
  font-weight: 500;
  padding: 14px 0px;
  background-color: transparent;
  text-decoration: underline;
  font-size: 18px;
  min-width: 0;
  text-align: left;
  &:hover, &:focus, &:active {
    background-color: transparent;
    text-decoration: none;
    color: $linkColorHover;
  }
  @media #{$maxSmall} {
    width: initial;
  }
}

.button--underline-accent {
  @extend .button--underline;
  color: $accent;
  &:hover, &:focus {
    color: $accent;
    text-decoration: none;
  }
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

button, input[type="reset"], input[type="submit"], .button, a.button, select {
  &:focus {
    outline: 0; *outline: inherit;
    box-shadow: 0 0 1px 1px rgba(200,200,200,0.1);
  }
}

::placeholder { color: #999; font-style: italic; }

// Hide clear text field ie 10+
::-ms-clear { display: none; }

// Remove the rounded corners
input[type="search"] { -webkit-appearance: none; }

// Hide the cancel button
::-webkit-search-cancel-button { -webkit-appearance: none; }

// Hide the magnifying glass
::-webkit-search-results-button { -webkit-appearance: none; }

::-webkit-textfield-decoration-container { }

// Hide number input spinner
::-webkit-inner-spin-button { -webkit-appearance: none; }
::-webkit-outer-spin-button { -webkit-appearance: none; }

// Style file upload button
::-ms-browse, ::-webkit-file-upload-button { }

.checkbox, .radio {
  position: relative;

  label {
    padding-left: 40px;
    cursor: pointer;
    line-height: 30px;
    margin-bottom: 0;

    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      transform: $transitionDefault;
    }

    &:before {
      left: 0; top: 0;
      width: 30px; height: 30px;
      border: 1px solid $grayLight;
      background-color: $grayLight;
      border-radius: $radius;
    }

    &:after {
      top: 4px;
      left: 9px;
      border: solid $black;
      border-width: 0 4px 4px 0;
      width: 11px;
      height: 18px;
      transform: rotate(45deg);
      opacity: 0;
    }
  }

  input[type="checkbox"], input[type="radio"] {
    position: absolute; top: 0; left: 0;
    z-index: -1;
    visibility: hidden;

    &:checked + label {
      &:before {
        border-color: $grayLight;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.radio {
  label {
    &:before, &:after {
      border-radius: 100%;
    }

    &:after {
      border: 0;
      transform: none;
      top: 10px;
      left: 10px;
      width: 10px;
      height: 10px;
      background-color: $black;
    }
  }
}

// Forms

.form-vertical {
  input, textarea, select, .checkbox, .radio {
    margin-bottom: $gutter/2;
  }
  .checkbox {
    margin-top: $gutter/2;
    margin-bottom: $gutter;
  }
}

fieldset {
  width: auto;

  p, ul, li {
    @include clearfix;
  }

  ul {
    margin-left: -20px;

    li {
      list-style: none; background: none; list-style-image: none;
      margin: 0 0 10px; float: left; width: 100%; padding: 0 0 0 20px;

      &.error {
        input {
          border-color: $error;
        }
      }

      &.space {
        margin-top: 25px;
      }

      @media #{$medium} {
        &.one-half { width: 50%; }
        &.one-fourth { width: 25%; }
      }
    }
  }
}

ul.buttons {
  margin: 0;
  list-style: none;
  li {
    display: inline-block;
    margin-right: $gutter;
  }
  @media #{$maxSmall} {
    li {
      display: block;
      width: 100%;
      margin-right: 0;
      text-align: center;
    }
  }
}

.a-c {
  .button, a.button, button {
    float: none; clear: none; display: inline-block;
  }
}

// Recaptcha
.grecaptcha-badge { display: none; }
